import { init as initColorScheme } from "./color_scheme.js";
import { init as initMenu } from "./menu.js";
import { init as initSelectionWithButtons } from "./selection_with_buttons.js";
import { init as initToggleValueOnClick } from "./toggle_value_on_click.js";
import { init as initFormWithFloatingLabels } from "./form_with_floating_labels.js";
import { init as initSearchAutocompletion } from "./search_autocomplete.js";
import initEmailConfirmation from "./email_confirmation.ts";
import initStripePricesTable from "./stripe_prices_table.ts";

function init() {
  initMenu();
  initColorScheme();
  initFormWithFloatingLabels();
  initSelectionWithButtons();
  initToggleValueOnClick();
  initSearchAutocompletion();
  initEmailConfirmation();
  initStripePricesTable();
}

window.addEventListener("pageshow", init);
