//
// Set an value on an input element by clicking a the buttons
//
//  Example usage:
//     <div class="js-checkbox-selection-with-button">
//       <input value="1" class="js-selected-value-input" type="text">
//
//       <div class="button" data-value="1">
//           Select Option 1
//       </div>
//       <div class="button" data-value="2">
//           Select Option 2
//       </div>
//       <div class="button" data-value="3">
//           Select Option 3
//       </div>
//       <div class="button" data-value="4">
//           Select Option 4
//       </div>
//     </div>
//
class SelectionWithButtons {
  constructor(element) {
    this.elem = element;
    this.valueFieldElem = this.elem.querySelector(".js-selected-value-input");
    this.shouldSubmitImmediately = this.elem.dataset.submit === "immediate";
    this.buttonElems = this.elem.querySelectorAll(".button");

    if (this.valueFieldElem.value) {
      this.setInitialSelection();
    }
    this.setEventListeners();
  }

  setInitialSelection() {
    this.buttonElems.forEach((buttonElem) => {
      if (buttonElem.dataset.value === this.valueFieldElem.value) {
        this.clearButtonsSection();
        this.setButtonSelection(buttonElem);
      }
    });
  }

  clearButtonsSection() {
    this.buttonElems.forEach((btn) => {
      btn.classList.remove("is-selected");
    });
  }

  setButtonSelection(btnElem) {
    btnElem.classList.add("is-selected");
  }

  submitImmediately() {
    const allInputFields = this.valueFieldElem.form.querySelectorAll("button");
    allInputFields.forEach((field) => (field.disabled = true));
    this.valueFieldElem.form.submit();
  }

  setEventListeners() {
    this.buttonElems.forEach((buttonElem) => {
      buttonElem.addEventListener("click", (event) => {
        event.preventDefault();
        this.clearButtonsSection();
        this.setButtonSelection(buttonElem);

        this.valueFieldElem.value = buttonElem.dataset.value;
        this.shouldSubmitImmediately && this.submitImmediately();
      });
    });
  }
}

export function init() {
  document
    .querySelectorAll(".js-select-value-with-buttons")
    .forEach(function (btnGroupElem) {
      new SelectionWithButtons(btnGroupElem);
    });
}
