import { apiRequest } from "@/modules/apiRequest/apiRequest";
import { HTTP_METHODS } from "@/api/http_methods";

export const fetchSkills = (query: string) =>
  apiRequest({
    name: "fetchSkills",
    path: "skills",
    queryParams: { query },
    method: HTTP_METHODS.GET,
  });
