export class ToggleValueOnClick {
  constructor(element) {
    this.elem = element;
    this.targetInputElem = document.querySelector(
      this.elem.dataset.targetInputSelector
    );
    this.onValue = this.elem.dataset.onValue;
    this.offValue = this.elem.dataset.offValue;
    this.currentValue = this.targetInputElem.value;

    this.setInitialSelection();
    this.setEventListeners();
  }

  setInitialSelection() {
    if (
      this.currentValue != this.onValue &&
      this.currentValue != this.offValue
    ) {
      // Default is off value
      this.currentValue = this.offValue;
    }

    this.setInputFieldValueToCurrentValue();
    this.updateClassName();
  }

  setInputFieldValueToCurrentValue() {
    this.targetInputElem.value = this.currentValue;
  }

  toggleValue() {
    this.currentValue =
      this.currentValue === this.onValue ? this.offValue : this.onValue;
  }

  updateClassName() {
    if (this.currentValue === this.onValue) {
      this.elem.classList.add("is-selected");
    } else {
      this.elem.classList.remove("is-selected");
    }
  }

  setEventListeners() {
    this.elem.addEventListener("click", (event) => {
      event.preventDefault();
      this.toggleValue();
      this.setInputFieldValueToCurrentValue();
      this.updateClassName();
    });
  }
}

export function init() {
  document.querySelectorAll(".js-toggle-value-on-click").forEach((elem) => {
    new ToggleValueOnClick(elem);
  });
}
