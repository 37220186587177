function checkInputElForValue(inputEl) {
  const labelsOfInput = inputEl.labels || [];
  if (inputEl.value) {
    inputEl.classList.add("has-value");
    labelsOfInput.forEach((label) => label.classList.add("has-value"));
  } else {
    inputEl.classList.remove("has-value");
    labelsOfInput.forEach((label) => label.classList.remove("has-value"));
  }
}

function attachListener(formEl) {
  const inputElements = formEl.querySelectorAll("input") || [];
  const textAreaElements = formEl.querySelectorAll("textarea") || [];
  [...inputElements, ...textAreaElements].forEach((inputEl) => {
    checkInputElForValue(inputEl);
    inputEl.addEventListener("change", (ev) => checkInputElForValue(ev.target));
  });
}

export function init() {
  const formElements =
    document.querySelectorAll(".js-form-with-floating-labels") || [];
  formElements.forEach(attachListener);
}
