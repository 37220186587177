import { getCookie } from "@/utils/cookies";
import { getLocale } from "@/i18n";
import { createQueryParamsString } from "./utils/createQueryParamsString";
import type { Options } from "./types";
import { ResponseError } from "./ResponseError";

const headers: HeadersInit = {
  "Content-Type": "application/json",
  Accept: "application/vnd.zavvy+json; version=1",
  "Accept-Language": getLocale(),
  "X-Zavvy-Utm-Source": getCookie("_zavvy_utm_source") || "",
  "X-Zavvy-Utm-Medium": getCookie("_zavvy_utm_medium") || "",
  "X-Zavvy-Utm-Content": getCookie("_zavvy_utm_content") || "",
  "X-Zavvy-Utm-Campaign": getCookie("_zavvy_utm_campaign") || "",
};

export const createRequestPath = (
  path: string,
  queryParams?: Parameters<typeof createQueryParamsString>["0"]
) =>
  `${process.env.API_BASE_URL}/${path}${
    queryParams ? "?" + createQueryParamsString(queryParams) : ""
  }`;

export const apiRequest = async function <R = Response, P = undefined>({
  path,
  name,
  method = "GET",
  queryParams,
  headers: customHeaders = {},
  signal,
  payload,
}: Options<P>): Promise<R> {
  const requestPath = createRequestPath(path, queryParams);

  const response = await fetch(requestPath, {
    method,
    body: payload ? JSON.stringify(payload) : undefined,
    signal,
    headers: {
      ...headers,
      ...customHeaders,
    },
    credentials: "include",
  });

  if (!response.ok) {
    let errorResponse = "";

    try {
      errorResponse = await response.clone().text();
    } catch (err) {
      // do nothing, keep empty errorResponse
    }

    throw new ResponseError<P>(
      {
        name,
        method,
        errorResponse,
        path: requestPath.split(process.env.API_BASE_URL || "").pop() as string,
      },
      response.clone()
    );
  }

  const data: R = response.status === 204 ? undefined : await response.json();

  return data;
};
