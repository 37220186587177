import { apiRequest } from "@/modules/apiRequest/apiRequest";
import { HTTP_METHODS } from "@/api/http_methods";

export const fetchAdminSkills = (query: string) =>
  apiRequest({
    name: "fetchAdminSkills",
    path: "admin/skills",
    queryParams: { query },
    method: HTTP_METHODS.GET,
  });
