import SkillAutoComplete from "../lib/skill_autocomplete.js";

function attachAutocomplete(formEl, callback = null) {
  const inputEl = formEl.querySelector("input.js-autocomplete-input");
  const clearEl = formEl.querySelector(".js-search-clear");
  const autocompleteResultList = formEl.querySelector(
    ".js-autocomplete-result-list"
  );

  if (autocompleteResultList) {
    autocompleteResultList.style.display = "block";
  }

  if (clearEl) {
    clearEl.addEventListener("click", () => {
      if (inputEl.value !== "") {
        inputEl.value = "";
        formEl.submit();
      }
    });
  }

  new SkillAutoComplete(formEl, {
    isAdminNamespace: false,
    beforeSearch: (input) => {
      const currentLabel = inputEl.value;
      if (input.trim() !== "" && input !== currentLabel) {
        inputEl.value = input;
      }
    },
    onSubmit: (result) => {
      result && (inputEl.value = result.label);
      if (callback !== null) {
        callback(inputEl.value);
      } else {
        formEl.submit();
      }
    },
  });
}

export function init() {
  document
    .querySelectorAll(".js-search-form")
    .forEach((searchForm) => attachAutocomplete(searchForm));
  document.addEventListener("search-mounted", (ev) => {
    ev.detail.formEl &&
      attachAutocomplete(ev.detail.formEl, ev.detail.callback);
  });
}
