// Caution: App.js is a shared entrypoint for Admin and User Interface

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import("@rails/ujs").then((ujs) => ujs.start());
import("@rails/activestorage").then((activestorage) => activestorage.start());
// +channels+ is Rails' ActionCable related Javascript.
// Rails' ActionCable code is available under +app/javascript+. Hence the double
// dots to move up the directory looking for the channels' Javascript.
import "../channels/channels.cjs";

import "../lib/sentry/sentry.ts";

// Custom JS
import "../interactions/interactions.js";
