import { ELEMENT_ID, getCurrentColorScheme } from "./color_scheme";

function onThemeToggled(event?: Event): void {
  event && event.stopPropagation();
  const { name } = getCurrentColorScheme();
  const isDarkMode = name === "dark";

  const lightPriceTableId = "prctbl_1Ln4OUGZRslY2S30ddTBUsxy";
  const darkPriceTableId = "prctbl_1LqFmzGZRslY2S30Jg5vnYsK";
  const publishableKey =
    "pk_live_51LhK07GZRslY2S30uIdQl8R13SCc6mO0V9loFM6fMjU0LwjzTAmX30gqg6ds0YQlLB7X76Nta5mryx6CyvVwPK2M00F9JA3r3F";

  const stripeTable = document.querySelector("stripe-pricing-table");
  const iframe = stripeTable?.shadowRoot?.querySelector("iframe");
  if (!stripeTable) return;

  // Lighthouse 💡 improvement to reach 100.
  iframe?.setAttribute("title", "Zavvy pricing table");

  const tableId = isDarkMode ? darkPriceTableId : lightPriceTableId;
  stripeTable.setAttribute("pricing-table-id", tableId);
  stripeTable.setAttribute("publishable-key", publishableKey);

  if (stripeTable.classList.contains("tw-hidden")) {
    stripeTable.classList.remove("tw-hidden");
  }
}

export default function () {
  const toggleElement = document.getElementById(ELEMENT_ID);
  toggleElement && toggleElement.addEventListener("click", onThemeToggled);

  onThemeToggled();
}
