import { isNullOrUndefinedOrNaN } from "@/utils/isNullOrUndefinedOrNaN";

export const createQueryParamsString = (
  params: Record<
    string,
    string | number | boolean | undefined | null | string[] | number[]
  >
): string => {
  let arrayParams: Array<[string, string][]> = [];

  let queryParams = Object.entries(params)
    .map(([key, value]) => {
      if (["number", "boolean"].includes(typeof value)) {
        return [key, (value as number | boolean).toString()];
      }

      if (Array.isArray(value)) {
        arrayParams = arrayParams.concat(
          value.map((value) => [[`${key}[]`, value.toString()]])
        );

        return [key, undefined];
      }

      return [key, value];
    })
    .filter(([, value]) => !isNullOrUndefinedOrNaN(value)) as Array<
    [string, string]
  >;

  for (const arrayParam of arrayParams) {
    queryParams = queryParams.concat(arrayParam);
  }

  return new URLSearchParams(queryParams).toString();
};
