import { setCookie, getCookie } from "@/utils/cookies";

export const ELEMENT_ID = "color-scheme-toggle";
const ICON_DARK_ID = "color-scheme-dark";
const ICON_LIGHT_ID = "color-scheme-light";
const ICON_AUTO_ID = "color-scheme-auto";
export const DARK_CLASS = "tw-dark";
export const AFTER_COLOR_SCHEME_UPDATE_EVENT_NAME =
  "class-list-updated-for-color-scheme";

const AVAILABLE_COLOR_SCHEMES = {
  auto: {
    name: "auto",
    icon: ICON_AUTO_ID,
    contentElement: "color-scheme-toggle__tooltip-auto",
    order: 2,
  },
  dark: {
    name: "dark",
    icon: ICON_DARK_ID,
    contentElement: "color-scheme-toggle__tooltip-dark",
    order: 1,
  },
  light: {
    name: "light",
    icon: ICON_LIGHT_ID,
    contentElement: "color-scheme-toggle__tooltip-light",
    order: 0,
  },
};

export function getCurrentColorScheme() {
  const storedName = getCookie(COOKIE_KEY) || "";
  const isValid = Object.keys(AVAILABLE_COLOR_SCHEMES).includes(storedName);
  const currentColorScheme =
    storedName && isValid
      ? AVAILABLE_COLOR_SCHEMES[storedName]
      : AVAILABLE_COLOR_SCHEMES.auto;
  return currentColorScheme;
}

function getNextColorScheme(scheme) {
  const nextOrderNumber =
    (scheme.order + 1) % Object.keys(AVAILABLE_COLOR_SCHEMES).length;
  return Object.values(AVAILABLE_COLOR_SCHEMES).find(
    (scheme) => scheme.order === nextOrderNumber
  );
}

const COOKIE_KEY = "zavvy_color_theme";

function setTooltipText(className) {
  Object.values(AVAILABLE_COLOR_SCHEMES)
    .map(({ contentElement }) => document.querySelector(`.${contentElement}`))
    .forEach((element) => {
      if (element.classList.contains(className)) {
        element.classList.remove("tw-hidden");
      } else {
        element.classList.add("tw-hidden");
      }
    });
}

function updateIcons(nextColorSchemeIcon) {
  const iconElementDark = document.getElementById(ICON_DARK_ID);
  const iconElementLight = document.getElementById(ICON_LIGHT_ID);
  const iconElementAuto = document.getElementById(ICON_AUTO_ID);

  iconElementDark && iconElementDark.classList.add("tw-hidden");
  iconElementLight && iconElementLight.classList.add("tw-hidden");
  iconElementAuto && iconElementAuto.classList.add("tw-hidden");

  const iconElementNext = document.getElementById(nextColorSchemeIcon);
  iconElementNext && iconElementNext.classList.remove("tw-hidden");
}

function setColorScheme(toggleElement, currentColorScheme) {
  const nextColorScheme = getNextColorScheme(currentColorScheme);

  if (currentColorScheme.name === "dark") {
    document.documentElement.classList.add(DARK_CLASS);
  } else if (
    currentColorScheme.name === "auto" &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    document.documentElement.classList.add(DARK_CLASS);
  } else {
    document.documentElement.classList.remove(DARK_CLASS);
  }
  toggleElement && (toggleElement.dataset.value = nextColorScheme.name);
  updateIcons(nextColorScheme.icon);
  toggleElement && setTooltipText(nextColorScheme.contentElement);

  document.documentElement.dispatchEvent(
    new Event(AFTER_COLOR_SCHEME_UPDATE_EVENT_NAME)
  );
  return currentColorScheme.name;
}

export function init() {
  const toggleElement = document.getElementById(ELEMENT_ID);
  const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

  /**
   * If the color scheme is 'auto' when loading the JavaScript,
   * and the preferred color scheme is 'dark',
   * we need to add the CSS class manually.
   * The server cannot prerender the HTML correctly in this case.
   */
  const currentColorScheme = getCurrentColorScheme();
  if (
    currentColorScheme.name === "auto" &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    document.documentElement.classList.add(DARK_CLASS);
  }

  if (toggleElement) {
    setColorScheme(toggleElement, currentColorScheme);

    toggleElement.addEventListener("click", (ev) => {
      ev.stopPropagation();
      const isValid = Object.keys(AVAILABLE_COLOR_SCHEMES).includes(
        toggleElement.dataset.value
      );
      if (isValid) {
        const name = setColorScheme(
          toggleElement,
          AVAILABLE_COLOR_SCHEMES[toggleElement.dataset.value]
        );
        setCookie(COOKIE_KEY, name, 31);
      }
    });
  }

  try {
    // Chrome & Firefox
    darkMediaQuery.addEventListener("change", () => {
      setColorScheme(toggleElement, getCurrentColorScheme());
    });
  } catch (errorSafari) {
    try {
      // Safari
      darkMediaQuery.addListener(() => {
        setColorScheme(toggleElement, getCurrentColorScheme());
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}
