import Autocomplete from "@trevoreyre/autocomplete-js";
import { fetchAdminSkills } from "@/api/admin/skills";
import { fetchSkills } from "@/api/skills";

class SkillAutoComplete {
  // containerElem: DOM Element thats contains the input for autocomplete
  // beforeSearch: Function to be called before search is performed.
  //                +input+ string is passed as an argument to the function.
  // onSubmit: Function to be called when user selects the suggestion.
  //                    +result+ object is passed to it.
  constructor(
    containerElem,
    {
      isAdminNamespace = false,
      beforeSearch = () => {},
      onSubmit = () => {},
    } = {}
  ) {
    const autocomplete = new Autocomplete(containerElem, {
      search: (input) => {
        beforeSearch(input);

        return new Promise((resolve) => {
          if (input.length < 3) {
            return resolve([]);
          }
          if (isAdminNamespace === true) {
            fetchAdminSkills(encodeURI(input)).then((data) => {
              resolve(data);
            });
          } else {
            fetchSkills(encodeURI(input)).then((data) => {
              resolve(data);
            });
          }
        });
      },
      getResultValue: (result) => result.label,
      renderResult: (result, props) => `
        <li class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-cursor-pointer hover:tw-bg-gray-50 dark:hover:tw-bg-gray-800 aria-selected:tw-bg-gray-50 dark:aria-selected:tw-bg-gray-800" ${props}>
          ${result.label}
        </li>
      `,
      onSubmit: onSubmit,
    });

    // Prevent +Enter+ keypress on autocomplete result list from
    // triggering submit on the form.
    //
    // Problem: When the user searches using Autocomplete and select a result by pressing
    // +Enter+, it triggers a submit on the form.
    //
    // Solution: +Autocomplete+ handles +Enter+ press by adding a +keydown+
    // event listener on the +input+.
    // We add another event listener on the same input and do nothing but
    // +preventDefault+. This way, we prevent the form submit from being
    // triggered and also let the +keydown+ event listener from +Autocomplete+
    // be triggered normally.
    // See:
    //  * https://github.com/trevoreyre/autocomplete/blob/e56c8be7d052145e265b70b5ed30ef4c4d659ba0/packages/autocomplete-js/Autocomplete.js#L101
    //  * https://github.com/trevoreyre/autocomplete/blob/e56c8be7d052145e265b70b5ed30ef4c4d659ba0/packages/autocomplete/AutocompleteCore.js#L62
    autocomplete.input.addEventListener("keydown", (event) => {
      const { key } = event;
      if (key === "Enter") {
        event.preventDefault();
      }
    });
  }
}

export default SkillAutoComplete;
