import { apiRequest } from "@/modules/apiRequest/apiRequest";
import { HTTP_METHODS } from "@/api/http_methods";
/**
 * function checks in the background if user has validated the email address
 * if true page is reloaded and redirected
 */
export default function (): void {
  const element = document.querySelector('[data-js-init="emailConfirmation"]');
  if (!element) return;
  let retryCounter = 0;
  const retryTimeoutMs = 5000;
  const retriesLimit = (10 * 1000 * 60) / retryTimeoutMs; // 120

  const checkEmailConfirmation = async () => {
    let retryCheck = true;
    try {
      const result = await apiRequest<{ is_confirmed: boolean } | null>({
        path: "users/email_confirmation",
        name: "emailConfirmation",
        method: HTTP_METHODS.GET,
      });
      if (result?.is_confirmed === true) {
        document.location.reload();
        retryCheck = false;
        return;
      }
    } catch (e) {
      // TypeError is most likely related to network issue and we
      // want to ignore that error here and not sent it to Sentry.
      if (!(e instanceof Error && e.name === "TypeError")) {
        console.error(e);
      }
    } finally {
      // check for 10 minutes only
      if (++retryCounter < retriesLimit && retryCheck) {
        setTimeout(checkEmailConfirmation, retryTimeoutMs);
      }
    }
  };
  setTimeout(checkEmailConfirmation, 2500);
}
