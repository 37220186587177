import { Options } from "./types";

export class ResponseError<P = undefined> extends Error {
  public response: Response;

  constructor(
    {
      name,
      method,
      errorResponse,
      path,
    }: Pick<Options<P>, "name" | "method" | "path"> & { errorResponse: string },
    response: Response
  ) {
    super();

    this.message = `${
      name ? `"${name}" call` : "Call"
    } failed: ${method} ${path} [${response.status}]${
      errorResponse ? `: ${errorResponse}` : ""
    }`;

    this.response = response;
  }
}
