import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import { getCookie } from "@/utils/cookies";

const REGISTERED_USER_UUID_COOKIE = "zavvy_registered_user_uuid";
const GUEST_USER_UUID_COOKIE = "zavvy_guest_user_uuid";

if (process.env.RAILS_ENV !== "development") {
  Sentry.init({
    dsn: "https://7254520f5edc43b0ba4a0534d69cf437@o359257.ingest.sentry.io/5222659",
    environment: process.env.RAILS_ENV,
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
    ],
    ignoreErrors: [
      "Failed to fetch",
      "Load failed",
      "NetworkError when attempting to fetch resource.",
      "Cancelled",
      "TypeError: Failed to fetch",
      "TypeError: Load failed",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: cancelled",
      "AbortError",
    ],
    denyUrls: [
      // Zavvy website downloads
      /file:\/\/\//i,
    ],
  });

  Sentry.setContext("user", {
    registered_user_uuid: getCookie(REGISTERED_USER_UUID_COOKIE),
    guest_user_uuid: getCookie(GUEST_USER_UUID_COOKIE),
  });
}
