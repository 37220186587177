export function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }

  return "";
}

export function setCookie(name: string, value: string, days = 7) {
  const date = new Date();

  // Expire in 7 days
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  document.cookie =
    name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
}

export function deleteCookie(name: string) {
  const date = new Date();

  // Expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

  document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
}
