import { nextTick } from "vue";
import { createI18n, type I18nOptions, type I18n } from "vue-i18n";
import dayjs from "dayjs";
import { captureException } from "@/monitoring/monitoring";
const SUPPORTED_LOCALES = ["en-US", "de-DE", "pt-PT", "es-ES"] as const;
export type SupportedLocales = (typeof SUPPORTED_LOCALES)[number];
export const FALLBACK_LOCALE = "en-US";
let customContextLocale: string | null = null;
let customContextLocaleFallback: string | null = null;

const i18nConfig: I18nOptions = {
  globalInjection: true, // defaults to true since version v9.2
  legacy: false,
  missingWarn: false,
  fallbackWarn: false,
};

export function getLocale() {
  const htmlElement = document.querySelector("html");
  const htmlLang: string = htmlElement?.getAttribute("lang") || "";
  const locale = SUPPORTED_LOCALES.includes(htmlLang as SupportedLocales)
    ? (htmlLang as SupportedLocales)
    : FALLBACK_LOCALE;
  return locale;
}

function extractLocale(input: string): string {
  const pattern = /^([a-z]{2}-[A-Z]{2})/; // Matches the locale part
  const match = input.match(pattern);

  if (match) {
    return match[0];
  }

  throw new Error("Invalid format");
}

function extractNumber(input: string): number {
  const pattern = /(\d+)$/; // Matches the number at the end of the string
  const match = input.match(pattern);

  if (match) {
    return parseInt(match[0]); // Converts the matched string to a number
  }

  throw new Error("No number found in the string");
}

function setupCustomLocales(locale: SupportedLocales, contextLocale: string) {
  customContextLocale = contextLocale;
  i18nConfig.locale = customContextLocale;

  if (extractLocale(customContextLocale) !== FALLBACK_LOCALE) {
    customContextLocaleFallback = `${FALLBACK_LOCALE}-${extractNumber(customContextLocale)}`;

    i18nConfig.fallbackLocale = {
      [`${customContextLocale}`]: [`${customContextLocaleFallback}`],
      [`${customContextLocaleFallback}`]: [locale],
      default: [FALLBACK_LOCALE],
    };
  } else {
    i18nConfig.fallbackLocale = {
      [`${customContextLocale}`]: [locale],
      default: [FALLBACK_LOCALE],
    };
  }
}

export function setupI18n(contextLocale: string | null): I18n {
  const locale = getLocale();

  if (contextLocale !== null) {
    setupCustomLocales(locale, contextLocale);
  } else {
    i18nConfig.locale = locale;
    i18nConfig.fallbackLocale = FALLBACK_LOCALE;
  }

  return createI18n(i18nConfig);
}

export async function setDayjsI18nLanguage(locale: SupportedLocales) {
  const shortLocale = locale.split("-")[0];
  try {
    await import(`dayjs/locale/${shortLocale}.js`);
    dayjs.locale(locale);
  } catch (err) {
    captureException(err as Error);
  }
}

async function mergeCustomLocaleAppMessages(
  i18n: I18n,
  path: string,
  locale: string
) {
  try {
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `${path}/locales/${customContextLocale}.yml`
    );

    i18n.global.mergeLocaleMessage(locale, messages);
  } catch (err) {
    // fail silently. It will use the fallback messages
  }
}

export async function mergeLocaleAppMessages(
  i18n: I18n,
  path: string,
  locale: SupportedLocales
) {
  // dynamically load locale file
  try {
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `${path}/locales/${locale}.yml`
    );

    i18n.global.mergeLocaleMessage(locale, messages);
  } catch (err) {
    if (process.env.NODE_ENV === "production") {
      try {
        await mergeLocaleAppMessages(i18n, path, FALLBACK_LOCALE);
      } catch (err) {
        captureException(err as Error);
      }
    }
  }

  if (customContextLocale !== null) {
    await mergeCustomLocaleAppMessages(i18n, path, customContextLocale);
  }

  if (customContextLocaleFallback !== null) {
    await mergeCustomLocaleAppMessages(i18n, path, customContextLocaleFallback);
  }

  return nextTick();
}

async function importLocale(locale: string, name: string) {
  const module = await import(
    /* webpackChunkName: "locale-[request]" */
    `./locales/${name}/${locale}.yml`
  );
  return module.default || {};
}

const localesToImport = [
  "components",
  "rrule",
  "shared",
  "modules",
  "layout",
  "base",
];

async function loadCustomLocales(i18n: I18n, customContextLocale: string) {
  try {
    const results = await Promise.allSettled(
      localesToImport.map((name) => importLocale(customContextLocale, name))
    );
    const localeMessages = results
      .filter((result) => result.status === "fulfilled")
      .map((result) => (result as PromiseFulfilledResult<string>).value);
    const mergedLocale = Object.assign({}, ...localeMessages);
    i18n.global.setLocaleMessage(customContextLocale, mergedLocale);
  } catch (err) {
    // fail silently. It will use the fallback messages
  }
}

export async function loadLocaleMessages(i18n: I18n, locale: SupportedLocales) {
  try {
    const localeMessages = await Promise.all(
      localesToImport.map((name) => importLocale(locale, name))
    );
    const mergedLocale = Object.assign({}, ...localeMessages);

    i18n.global.setLocaleMessage(locale, mergedLocale);
  } catch (err) {
    if (process.env.NODE_ENV === "production") {
      try {
        await loadLocaleMessages(i18n, FALLBACK_LOCALE);
      } catch (err) {
        captureException(err as Error);
      }
    }
  }

  if (customContextLocale !== null) {
    await loadCustomLocales(i18n, customContextLocale);
  }

  if (customContextLocaleFallback !== null) {
    await loadCustomLocales(i18n, customContextLocaleFallback);
  }

  return nextTick();
}
