function initMobileMenu() {
  const mobileMenu = document.getElementById("mobile-menu");
  const mobileMenuNav = document.getElementById("mobile-menu-nav");
  const mobileMenuPopover = document.getElementById("menu-user-popover");
  const hamburgerMenuToggle = document.getElementById("mobile-menu-toggle");
  const hamburgerMenuToggleIconActive = document.getElementById(
    "mobile-menu-toggle__active"
  );
  const hamburgerMenuToggleIconInactive = document.getElementById(
    "mobile-menu-toggle__inactive"
  );
  if (
    mobileMenu &&
    mobileMenuNav &&
    hamburgerMenuToggle &&
    hamburgerMenuToggleIconActive &&
    hamburgerMenuToggleIconInactive
  ) {
    let mobileMenuIsOpen = false;

    hamburgerMenuToggle.addEventListener("click", () => {
      mobileMenuIsOpen = !mobileMenuIsOpen;

      if (mobileMenuIsOpen) {
        mobileMenu.classList.add("tw-shadow-bottom");
        mobileMenuNav.classList.add("tw-block");
        mobileMenuNav.classList.remove("tw-hidden");
        mobileMenuPopover.classList.add("tw-block");
        mobileMenuPopover.classList.remove("tw-hidden");
        hamburgerMenuToggleIconActive.classList.add("tw-block");
        hamburgerMenuToggleIconActive.classList.remove("tw-hidden");
        hamburgerMenuToggleIconInactive.classList.add("tw-hidden");
        hamburgerMenuToggleIconInactive.classList.remove("tw-block");
      } else {
        mobileMenu.classList.remove("tw-shadow-bottom");
        mobileMenuNav.classList.remove("tw-block");
        mobileMenuNav.classList.add("tw-hidden");
        mobileMenuPopover.classList.remove("tw-block");
        mobileMenuPopover.classList.add("tw-hidden");
        hamburgerMenuToggleIconActive.classList.remove("tw-block");
        hamburgerMenuToggleIconActive.classList.add("tw-hidden");
        hamburgerMenuToggleIconInactive.classList.remove("tw-hidden");
        hamburgerMenuToggleIconInactive.classList.add("tw-block");
      }
    });
  }
}

function closePopover(menuUserPopoverToggle, menuUserPopover) {
  menuUserPopoverToggle.setAttribute("aria-expanded", "false");
  menuUserPopover.classList.remove("md:tw-opacity-1");
  menuUserPopover.classList.remove("md:tw-block");
  menuUserPopover.classList.add("md:tw-opacity-0");
  menuUserPopover.classList.add("md:tw-hidden");
}

function initUserPopover() {
  const menuUserPopover = document.getElementById("menu-user-popover");
  const menuUserPopoverToggle = document.getElementById(
    "menu-user-popover-toggle"
  );

  if (menuUserPopover && menuUserPopoverToggle) {
    const focusables = menuUserPopover.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    let popoverIsOpen = false;

    menuUserPopoverToggle.addEventListener("click", (ev) => {
      ev.stopPropagation();
      popoverIsOpen = !popoverIsOpen;

      if (popoverIsOpen) {
        menuUserPopoverToggle.setAttribute("aria-expanded", "true");
        menuUserPopover.classList.add("md:tw-opacity-1", "md:tw-block");
        menuUserPopover.classList.remove("md:tw-opacity-0", "md:tw-hidden");
        focusables[0].focus();
      } else {
        closePopover(menuUserPopoverToggle, menuUserPopover);
      }
    });

    document.body.addEventListener("click", () => {
      if (popoverIsOpen) {
        popoverIsOpen = false;
        closePopover(menuUserPopoverToggle, menuUserPopover);
      }
    });

    document.addEventListener("keydown", (ev) => {
      if (ev.key === "Escape" && popoverIsOpen) {
        popoverIsOpen = false;
        closePopover(menuUserPopoverToggle, menuUserPopover);
      }
    });
  }
}

export function init() {
  initMobileMenu();
  initUserPopover();
}
